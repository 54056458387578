// Utils Imports
import { URL } from "../variables";

const defaultHeaders = (token) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

const handleResponse = async (response) => {
  // if (!response.ok) {
  //   throw new Error("Network response was not ok");
  // }

  const resp = await response.json();

  if (resp.message === "Unauthenticated.") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return;
  }

  if (resp.errors != null || resp.error != null) {
    let errors = Object.values(resp.errors);
    let erro = "";
    for (let i = 0; i < errors.length; i++) {
      if (i !== errors.length - 1) erro += errors[i] + "\n";
      else erro += errors[i];
    }
    return {status:false,message:erro}
  }

  return resp;
};

export const get = async (endpoint, token) => {
  const response = await fetch(`${URL}${endpoint}`, {
    method: "GET",
    headers: defaultHeaders(token),
  });
  return handleResponse(response);
};

export const post = async (endpoint, data, token) => {
  const response = await fetch(`${URL}${endpoint}`, {
    method: "POST",
    headers: defaultHeaders(token),
    body: JSON.stringify(data),
  });
  return handleResponse(response);
};

export const put = async (endpoint, data, token) => {
  const response = await fetch(`${URL}${endpoint}`, {
    method: "PUT",
    headers: defaultHeaders(token),
    body: JSON.stringify(data),
  });
  return handleResponse(response);
};

export const del = async (endpoint, token) => {
  const response = await fetch(`${URL}${endpoint}`, {
    method: "DELETE",
    headers: defaultHeaders(token),
  });
  return handleResponse(response);
};

// Library
import React, { Component } from "react";

// Components
import { Modal, Button } from "react-bootstrap";

class SuccessModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SuccessModal;

// Library Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal, Button } from "react-bootstrap";

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post } from "../../Services/api";
import Search from "../ReusableComponents/Search";
import { Pagination } from '@material-ui/lab';

const SuccessModal = ({ show, handleClose, handleTableUpdate }) => (
  <Modal
    show={show}
    onHide={() => {
      handleClose();
      handleTableUpdate();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Sucesso!</Modal.Title>
    </Modal.Header>
    <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={() => {
          handleClose();
          handleTableUpdate();
        }}
      >
        Fechar
      </Button>
    </Modal.Footer>
  </Modal>
);

class AvaliarQuestoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      demandas: [],
      questoes: [],
      loading: true,
      redirect: false,
      categoria: null,
      demanda: null,
      pagination: null,
      isModalOpen: false,
      novoComentario: "",
      status: "",
      comentarios: [],
      isCommentsLoading: false,
      showSuccessModal: false,
      questaoId: null,
      isModalLoading: false,
      search: '',
    };
  }

  componentDidMount() {
    this.fetchQuestoes();
  }

  fetchQuestoes = async (page = 1, search = '') => {
    try {
      const { token } = this.props;
      const resp = await get(
        `api/professor/get_all_questoes?search=${search}&page=${page}`,
        token
      );
      const questoesWithDemanda = resp.questoes;

      this.setState((prevState) => ({
        questoes: questoesWithDemanda,
        pagination: resp.pagination,
        loading: false
      }));
    } catch (error) {
      this.setState({ redirect: true });
    }
  };

  fetchComentarios = async (questaoId) => {
    this.setState({ isCommentsLoading: true, isModalLoading: true });
    try {
      const { token } = this.props;
      const res = await get(`api/professor/get_comentario/${questaoId}`, token);
      const comentarios = res.map((item) => ({
        comentario: item.comentario,
        user_name: item.user_name,
        created_at: item.created_at,
      }));

      this.setState({
        comentarios,
        isCommentsLoading: false,
        isModalLoading: false,
      });
    } catch (error) {
      this.setState({ isCommentsLoading: false, isModalLoading: false });
    }
  };

  editStatusQuestao = async (status, questaoId) => {
    try {
      const { token } = this.props;
      await post(
        `api/professor/update_status_questao/${questaoId}`,
        { status },
        token
      );
      this.setState({ showSuccessModal: true });
    } catch (error) { }
  };

  handleAddComentario = async (questaoId) => {
    const { token, user } = this.props;
    const { novoComentario } = this.state;
    try {
      await post(
        "api/professor/store_comentario",
        { questao_id: questaoId, comentario: novoComentario },
        token
      );
      this.setState((prevState) => ({
        comentarios: [
          ...prevState.comentarios,
          {
            comentario: novoComentario,
            user_name: user.name,
            created_at: new Date().toISOString(),
          },
        ],
        novoComentario: "",
      }));
    } catch (error) { }
  };

  openModal = (questaoId) => {
    this.setState({ isModalOpen: true, questaoId, isModalLoading: true });
    this.fetchComentarios(questaoId);
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleNovoComentarioChange = (event) => {
    this.setState({ novoComentario: event.target.value });
  };

  handleStatusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  handleSubmitStatus = () => {
    this.editStatusQuestao(this.state.status, this.state.questaoId);
    this.closeModal();
  };

  handleSubmitComentario = () => {
    this.handleAddComentario(this.state.questaoId);
  };

  handleTableUpdate = () => {
    window.location.reload();
  };

  render() {
    const {
      redirect,
      loading,
      isModalOpen,
      isCommentsLoading,
      comentarios,
      novoComentario,
      status,
      showSuccessModal,
      isModalLoading,
    } = this.state;

    const questoesFiltradas = this.state.questoes;

    if (redirect) {
      return <Redirect to="/error" />;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title">Lista de Questões Pendentes</h4>
              <p>
                Aqui são listados todas as questões pendentes do seu sistema
              </p>
              <div className="row">
                <div className="flex jcsb flex-wrap mb-4">

                  <Search placeholder="Pesquisar pelo nome da categoria" ValueChange={value => {
                    clearTimeout(this.pesquisarTimeout);
                    this.pesquisarTimeout = setTimeout(() => {
                      let page = { ...this.state.pagination };
                      page.current_page = 1;
                      this.setState({ pagination: page, search: value, loading: true });
                      this.fetchQuestoes(1, value);
                    }, 2000);
                  }} />



                </div>

                {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div >

                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Enunciado</th>
                              <th>Disciplina</th>
                              <th>Nome do elaborador</th>
                              <th>Identificador da demanda</th>
                              <th>Data da última atualização</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questoesFiltradas.length === 0 ? (
                              <tr>
                                <td colSpan="5">
                                  {/* <div
                                    className="alert alert-warning"
                                    role="alert"
                                  > */}
                                  Nenhuma questão pendente.
                                  {/* </div> */}
                                </td>
                              </tr>
                            ) : (
                              questoesFiltradas.map((questao, index) => (
                                <tr key={index}>
                                  <td>{questao.text_questao}</td>
                                  <td>{questao.categoria.name}</td>
                                  <td>{questao.user.name}</td>

                                  <td>{questao.demanda.id}</td>
                                  <td>{formatDate(questao.updated_at)}</td>
                                  <td>
                                    <Link
                                      to={`/avaliar-questoes/show/${questao.id}`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-warning icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        title="Gerenciar"
                                      >
                                        <BsFillEyeFill
                                          size={17}
                                          color="white"
                                        />
                                      </span>
                                    </Link>

                                    <span
                                      onClick={() => this.openModal(questao.id)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-info icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        data-toggle="tooltip"
                                        title="Avaliar Questão"
                                      >
                                        <FaStar color="white" size={17} />
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Pagination
                      className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={this.state.pagination.last_page}
                      value={this.state.pagination.current_page}
                      onChange={(_, value) => {
                        console.log(_, value);
                        let page = { ...this.state.pagination };
                        page.current_page = value;
                        this.setState({ pagination: page, loading: true });
                        this.fetchQuestoes(value, this.state.search);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={isModalOpen} onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5 fw-bold">Avaliar Questão</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {isModalLoading ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="mb-4">
                  <h5 className="fw-semibold text-dark mb-3">Comentários</h5>
                  {isCommentsLoading ? (
                    <p className="text-muted text-center">
                      Carregando comentários...
                    </p>
                  ) : (
                    <ul className="list-unstyled">
                      {comentarios.map((comentario, index) => (
                        <li
                          key={index}
                          className="d-flex align-items-start mb-3"
                        >
                          <i className="bi bi-person-circle fs-4 text-muted me-3"></i>
                          <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-center">
                              <strong className="text-muted me-2">
                                {comentario.user_name}
                              </strong>
                              <small className="text-muted">
                                {formatDate(comentario.created_at)}
                              </small>
                            </div>
                            <p className="mb-0 text-dark">
                              {comentario.comentario}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="mb-4">
                  <h5 className="fw-semibold text-dark mb-3">
                    Adicionar Comentário
                  </h5>
                  <textarea
                    className="form-control mb-2"
                    value={novoComentario}
                    onChange={this.handleNovoComentarioChange}
                    placeholder="Escreva seu comentário aqui..."
                  />
                  <Button
                    variant="secondary"
                    onClick={this.handleSubmitComentario}
                  >
                    Adicionar
                  </Button>
                </div>

                <div className="mb-4">
                  <h5 className="fw-semibold text-dark mb-3">Avaliar</h5>
                  {["aprovado", "recusado"].map((option) => (
                    <div
                      key={option}
                      className="d-flex align-items-center mb-2"
                    >
                      <input
                        type="radio"
                        id={option}
                        className="form-check-input me-2"
                        value={option}
                        checked={status === option}
                        onChange={this.handleStatusChange}
                      />
                      <label htmlFor={option} className="form-check-label">
                        {option === "aprovado" ? "Aprovar" : "Desaprovar"}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-end">
                  <Button variant="success" onClick={this.handleSubmitStatus}>
                    Enviar Avaliação
                  </Button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        <SuccessModal
          show={showSuccessModal}
          handleClose={() => this.setState({ showSuccessModal: false })}
          handleTableUpdate={this.handleTableUpdate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(AvaliarQuestoes);

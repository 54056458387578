// Library
import React, { Component } from "react";

// Components
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingSkeleton extends Component {
  render() {
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress />
      </div>
    );
  }
}

export default LoadingSkeleton;

// Library Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import JoditEditor from "jodit-react";

// Components Imports
import SuccessModal from "./Components/SuccesModal";
import EditModal from "./Components/EditModal";
import LoadingSkeleton from "../../components/LoadingSkeleton";

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post, put } from "../../Services/api";
import removeHtmlTags from "../../Auxiliar/removeHtmlTags";

const AvaliarQuestaoRecusada = ({ match, token, history }) => {
  const [questao, setQuestao] = useState({
    name: "",
    alternativas: [],
    user: {},
  });
  const [demanda, setDemanda] = useState({});
  const [comentarios, setComentarios] = useState([]);
  const [novoComentario, setNovoComentario] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isCommentsLoading, setIsCommentsLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditQuestaoModal, setShowEditQuestaoModal] = useState(false);
  const [showEditAlternativaModal, setShowEditAlternativaModal] =
    useState(false);
  const [currentAlternativaIndex, setCurrentAlternativaIndex] = useState(null);
  const [currentAlternativaValue, setCurrentAlternativaValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchQuestao(), fetchComentarios()]);
    };
    fetchData();
  }, []);

  const fetchQuestao = async () => {
    try {
      const { id } = match.params;
      const resp = await get(`api/professor/get_questao_review/${id}`, token);
      setQuestao(resp.questao || {});
      setDemanda(resp.demanda || {});
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchComentarios = async () => {
    try {
      const { id } = match.params;
      const res = await get(`api/professor/get_comentario/${id}`, token);
      setComentarios(
        res.map((item) => ({
          comentario: item.comentario,
          user_name: item.user_name,
          created_at: item.created_at,
        }))
      );
    } catch {
    } finally {
      setIsCommentsLoading(false);
    }
  };

  const updateQuestaoStatus = async (status) => {
    try {
      const { id } = match.params;
      await post(
        `api/professor/update_status_questao/${id}`,
        { status },
        token
      );
      setShowSuccessModal(true);
    } catch {}
  };

  const editQuestao = async (questao) => {
    try {
      const { id } = match.params;
      await put(
        `api/professor/update_questao/${id}`,
        {
          name: questao.name,
          text_questao: removeHtmlTags(questao.name),
          alternativas: questao.alternativas,
        },
        token
      );
      setShowSuccessModal(true);
    } catch {}
  };

  const handleAlternativaChange = (index, value) => {
    const alternativas = [...questao.alternativas];
    alternativas[index].name = value;
    setQuestao((prev) => ({ ...prev, alternativas }));
  };

  const handleSaveAlternativa = () => {
    handleAlternativaChange(currentAlternativaIndex, currentAlternativaValue);
    toggleEditAlternativaModal(null);
  };

  const toggleEditQuestaoModal = () => setShowEditQuestaoModal((prev) => !prev);
  const toggleEditAlternativaModal = (index) => {
    setShowEditAlternativaModal((prev) => !prev);
    setCurrentAlternativaIndex(index);
    setCurrentAlternativaValue(questao.alternativas[index]?.name || "");
  };

  const letras = ["a", "b", "c", "d", "e"];

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  const editorConfig = {
    readonly: true,
    toolbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-0">Tela de Avaliação</h1>
        <Button variant="secondary" onClick={() => history.goBack()}>
          Voltar
        </Button>
      </div>

      <Card className="mb-4 shadow-sm border-0">
        <Card.Body>
          <Card.Title className="fs-4 fw-bold mb-3">
            Dados da Questão
          </Card.Title>
          <hr />
          {questao.user && (
            <p className="mb-2">
              <i className="bi bi-person-fill me-2 text-secondary"></i>
              <strong>Criador:</strong> {questao.user.name}
            </p>
          )}
          <p className="mb-2">
            <i className="bi bi-calendar-event-fill me-2 text-secondary"></i>
            <strong>Data de Criação:</strong> {formatDate(questao.created_at)}
          </p>
          <p className="mb-2">
            <i className="bi bi-briefcase-fill me-2 text-secondary"></i>
            <strong>Demanda Relacionada:</strong> {demanda.name}
          </p>
          <p className="mb-2">
            <i className="bi bi-bar-chart-fill me-2 text-secondary"></i>
            <strong>Nível de Dificuldade:</strong> {questao.dificuldade}
          </p>
          <p className="mb-2">
            <i className="bi bi-mortarboard-fill me-2 text-secondary"></i>
            <strong>Escolaridade:</strong> {questao.escolaridade}
          </p>
          <p className="mb-2">
            <i className="bi bi-tags-fill me-2 text-secondary"></i>
            <strong>Palavras-Chaves: </strong>
            {questao.keywords?.length > 0
              ? questao.keywords.join(", ")
              : "Nenhuma palavra-chave vinculada"}
          </p>
        </Card.Body>
      </Card>

      <Card className="mb-4 shadow-sm border-0">
        <Card.Body>
          <Card.Title className="text-center fs-4 fw-bold mb-4">
            Enunciado da Questão
          </Card.Title>
          <div className="d-flex justify-content-between align-items-start mb-4">
            <div className="flex-grow-1 me-3">
              <JoditEditor value={questao.name} config={editorConfig} />
            </div>
            <Button variant="outline-primary" onClick={toggleEditQuestaoModal}>
              Editar
            </Button>
          </div>
          <ul className="list-group list-group-flush mb-4">
            {questao.alternativas?.map((alternativa, index) => (
              <li key={index} className="list-group-item border-0 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <strong className="me-3">{letras[index]})</strong>
                  <div className="flex-grow-1 me-3">
                    <JoditEditor
                      value={alternativa.name}
                      config={editorConfig}
                    />
                  </div>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => toggleEditAlternativaModal(index)}
                  >
                    Editar
                  </Button>
                </div>
              </li>
            ))}
          </ul>
          <div className="text-center">
            <Button variant="success" onClick={() => editQuestao(questao)}>
              Salvar Alterações
            </Button>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4 shadow-sm border-0">
        <Card.Body>
          <Card.Title>Resposta Correta</Card.Title>
          <p>
            {questao.correta + ")" || "Nenhuma questão correta encontrada."}
          </p>
        </Card.Body>
      </Card>

      <Card className="mb-4 shadow-sm border-0">
        <Card.Body className="p-4">
          <Card.Title className="fw-semibold fs-5 text-dark mb-3">
            Comentários
          </Card.Title>
          <div>
            {isCommentsLoading ? (
              <p className="text-muted text-center">
                Carregando comentários...
              </p>
            ) : (
              <ul className="list-unstyled">
                {comentarios.map((comentario, index) => (
                  <li
                    key={index}
                    className="d-flex align-items-start mb-3"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="me-3">
                      <i className="bi bi-person-circle fs-4 text-muted"></i>
                    </div>
                    <div className="flex-grow-1">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${index}`}>
                            Criado em: {formatDate(comentario.created_at)}
                          </Tooltip>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <strong className="text-muted me-2">
                            {comentario.user_name}
                          </strong>
                          <small className="text-muted">
                            {formatDate(comentario.created_at)}
                          </small>
                        </div>
                      </OverlayTrigger>
                      <p className="mb-0 text-dark">{comentario.comentario}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-4 shadow-sm border-0">
        <Card.Body>
          <Button
            variant="success"
            className="mt-2"
            onClick={() => updateQuestaoStatus("pendente")}
          >
            Enviar para Avaliação
          </Button>
        </Card.Body>
      </Card>

      <SuccessModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
      />

      <EditModal
        show={showEditQuestaoModal}
        handleClose={toggleEditQuestaoModal}
        title="Editar Enunciado da Questão"
        value={questao.name}
        onChange={(value) => setQuestao((prev) => ({ ...prev, name: value }))}
        onSave={toggleEditQuestaoModal}
      />

      <EditModal
        show={showEditAlternativaModal}
        handleClose={toggleEditAlternativaModal}
        title="Editar Alternativa"
        value={currentAlternativaValue}
        onChange={setCurrentAlternativaValue}
        onSave={handleSaveAlternativa}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(
  withRouter(AvaliarQuestaoRecusada)
);

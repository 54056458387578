// Library Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post } from "../../Services/api";

const SuccessModal = ({ show, handleClose, handleTableUpdate }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Sucesso!</Modal.Title>
    </Modal.Header>
    <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={() => {
          handleClose();
          handleTableUpdate();
        }}
      >
        Fechar
      </Button>
    </Modal.Footer>
  </Modal>
);

const QuestoesRecusadas = ({ token, user }) => {
  const [demandas, setDemandas] = useState([]);
  const [questoes, setQuestoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [questaoId, setQuestaoId] = useState(null);

  useEffect(() => {
    const fetchDemandas = async () => {
      try {
        const { demandas } = await get(`api/professor/get_demandas`, token);
        await Promise.all(
          demandas.map((demanda) => fetchAtribuicoes(demanda.id))
        );
        setDemandas(demandas);
        setLoading(false);
      } catch (error) {
        setRedirect(true);
      }
    };

    fetchDemandas();
  }, [token]);

  const fetchAtribuicoes = async (demandaId) => {
    try {
      const { atribuicoes } = await get(
        `api/professor/get_atribuicoes/${demandaId}`,
        token
      );
      await Promise.all(
        atribuicoes.map((atribuicao) => fetchQuestoes(atribuicao.id))
      );
    } catch (error) {
      setRedirect(true);
    }
  };

  const fetchQuestoes = async (atribuicaoId) => {
    try {
      const resp = await get(
        `api/professor/get_all_questoes_demanda/${atribuicaoId}?&questoes=true`,
        token
      );
      setQuestoes((prev) => [
        ...prev,
        ...resp.questoes.map((questao) => ({
          ...questao,
          demanda: resp.demanda,
        })),
      ]);
    } catch (error) {
      setRedirect(true);
    }
  };

  const fetchComentarios = async (questaoId) => {
    setIsCommentsLoading(true);
    try {
      const res = await get(`api/professor/get_comentario/${questaoId}`, token);
      setComentarios(
        res.map((item) => ({
          comentario: item.comentario,
          user_name: item.user_name,
          created_at: item.created_at,
        }))
      );
    } catch (error) {
    } finally {
      setIsCommentsLoading(false);
    }
  };

  const editStatusQuestao = async (status, questaoId) => {
    try {
      await post(
        `api/professor/update_status_questao/${questaoId}`,
        { status },
        token
      );
      setShowSuccessModal(true);
    } catch (error) {}
  };

  const handleOpenModal = (questaoId) => {
    setIsModalOpen(true);
    setQuestaoId(questaoId);
    fetchComentarios(questaoId);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmitStatus = () => {
    editStatusQuestao("pendente", questaoId);
    handleCloseModal();
  };

  const handleTableUpdate = () => window.location.reload();

  if (redirect) {
    return <Redirect to="/error" />;
  }

  const questoesFiltradas = questoes.filter(
    (questao) =>
      questao.user.elaborador_opcoes &&
      !questao.user.elaborador_opcoes.can_do_audition &&
      questao.statusAprovacao === "recusado"
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="mt-0 header-title">Lista de Questões Recusadas</h4>
            <p>Aqui são listados todas as questões recusadas do seu sistema</p>
            <div className="table-responsive mb-0">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nome do elaborador</th>
                      <th>Nome da demanda</th>
                      <th>Data de criação</th>
                      <th>Data de última alteração</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questoesFiltradas.length === 0 ? (
                      <tr>
                        <td colSpan="5">
                          {/* <div className="alert alert-warning" role="alert"> */}
                            Nenhuma questão recusada.
                          {/* </div> */}
                        </td>
                      </tr>
                    ) : (
                      questoesFiltradas.map((questao, index) => (
                        <tr key={index}>
                          <td>{questao.user.name}</td>
                          <td>{questao.demanda.name}</td>
                          <td>{formatDate(questao.created_at)}</td>
                          <td>{formatDate(questao.updated_at)}</td>
                          <td>
                            <Link
                              to={`/questoes-recusadas/show/${questao.id}`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <span
                                className="inline-flex align-center jcc bg-warning icon"
                                style={{
                                  width: "2em",
                                  height: "2em",
                                  borderRadius: "7px",
                                  marginRight: "2px",
                                }}
                                title="Gerenciar"
                              >
                                <BsFillEyeFill size={17} color="white" />
                              </span>
                            </Link>

                            <span
                              onClick={() => handleOpenModal(questao.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="inline-flex align-center jcc bg-info icon"
                                style={{
                                  width: "2em",
                                  height: "2em",
                                  borderRadius: "7px",
                                  marginRight: "2px",
                                }}
                                data-toggle="tooltip"
                                title="Avaliar Questão"
                              >
                                <FaStar color="white" size={17} />
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Avaliar Questão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isCommentsLoading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <h5>Comentários</h5>
              <ul className="list-unstyled">
                {comentarios.map((comentario, index) => (
                  <li key={index} className="d-flex align-items-start mb-3">
                    <div className="me-3">
                      <i className="bi bi-person-circle fs-4 text-muted"></i>
                    </div>
                    <div className="flex-grow-1">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            Criado em: {formatDate(comentario.created_at)}
                          </Tooltip>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <strong className="text-muted">
                            {comentario.user_name}
                          </strong>
                          <small className="text-muted">
                            {formatDate(comentario.created_at)}
                          </small>
                        </div>
                      </OverlayTrigger>
                      <p>{comentario.comentario}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="d-flex justify-content-end">
                <Button variant="success" onClick={handleSubmitStatus}>
                  Enviar para Avaliação
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <SuccessModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        handleTableUpdate={handleTableUpdate}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(QuestoesRecusadas);

// Library Imports
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import JoditEditor from "jodit-react";

class EditModal extends Component {
    render() {
      return (
        <Modal show={this.props.show} onHide={this.props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JoditEditor
              value={this.props.value}
              onChange={this.props.onChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={this.props.onSave}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

  export default EditModal;

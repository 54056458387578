// Library Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaListAlt } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Pagination } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "react-bootstrap";
import Tooltip from "react-tooltip";

// Components Imports
import { Redirect, Link } from "react-router-dom";
import Search from "../ReusableComponents/Search";
import DefaultButton from "../ReusableComponents/DefaultButton";
import ModalQuestao from "./ModalQuestao";
import TextReadMore from "../ReusableComponents/TextReadMore";

// Utils Imports
import { logout } from "../../actions/AppActions";
import { get, del } from "../../Services/api";

class Questoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/",
      loading_screen: false,
      questoes: [],
      pagination: {
        page: 1,
        last_page: 1,
      },
      categoria: { name: "" },
      demanda: { name: "" },
      demanda_professor: {},
      msgErro: "",
      show_warning: false,
      show_erro: false,
      unidade_to_delete: "",
      search: "",
      loading_search: true,
      showModal: false,
      questaoSelect: null,
    };
  }

  componentDidMount() {
    this.get_questoes(this.state.page);
  }

  get_questoes(page, search = "", busca = true) {
    const { demanda_id } = this.props.match.params;
    const { token, logout } = this.props;

    if (busca) {
      if (!this.state.loading_search) {
        this.setState({ loading_search: true });
      }
    } else if (!this.state.loading_screen) {
      this.setState({ loading_screen: true });
    }

    get(
      `api/professor/get_all_questoes_demanda_user/${demanda_id}?page=${page}&search=${search}&questoes=true`,
      token
    )
      .then((resp) => {
        if (!resp) {
          logout();
          return;
        }

        this.setState({
          loading_search: false,
          categoria: resp.categoria,
          demanda: resp.demanda,
          demanda_professor: resp.demanda_professor,
          questoes: resp.questoes,
          loading_screen: false,
          pagination: resp.pagination,
        });
      })
      .catch((err) => {
        this.setState({ redirect: true });
        console.error(err);
      });
  }

  delete_questao(id) {
    const { token, logout } = this.props;

    del(`api/professor/delete_questao/${id}`, token)
      .then((resp) => {
        if (!resp) {
          logout();
          return;
        }

        this.setState({ show_warning: false });
        this.get_questoes(this.state.page, this.state.search);
      })
      .catch((err) => {
        this.setState({ redirect: true });
        console.error(err);

        this.setState({
          msgErro: err.message,
          show_warning: false,
          show_erro: true,
        });
      });
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <ModalQuestao
          showModal={this.state.showModal}
          setShowModal={(e) => this.setState({ showModal: e })}
          questao={this.state.questaoSelect}
        />

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <div style={{ float: "right" }}>
                <DefaultButton
                  bg="secondary"
                  text="Voltar"
                  icon={<RiArrowGoBackLine />}
                  to={`/demandas/show/${this.state.demanda.id}`}
                />
              </div>
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Lista de Questões
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Aqui são listados todas as questões da Demanda "
                <b>{this.state.demanda.id}</b>", na Disciplina "
                <b>{this.state.categoria.name}</b>"
              </p>
              <p style={{ fontSize: "1rem", color: "black", marginBottom: 5 }}>
                <b>Questões objetivas:</b>{" "}
                {this.state.demanda_professor.questoes}
                {this.state.demanda_professor.numero_questoes == null
                  ? ""
                  : "/" + this.state.demanda_professor.numero_questoes}
              </p>
              <p style={{ fontSize: "1rem", color: "black", marginBottom: 5 }}>
                <b>Questões dissertativas:</b>{" "}
                {this.state.demanda_professor.questoes_dissertativas}
                {this.state.demanda_professor.numero_questoes_dissertativas ==
                null
                  ? ""
                  : "/" +
                    this.state.demanda_professor.numero_questoes_dissertativas}
              </p>

              {this.state.demanda_professor.id != null && (
                <TextReadMore
                  title="Descritivo: "
                  element="div"
                  limit={1000}
                  style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
                >
                  {this.state.demanda_professor?.descritivo}
                </TextReadMore>
              )}
              <br />
              <br />

              <div className="row">
                <div className="col-12">
                  {/* <hr /> */}
                  {this.state.loading_screen == false && (
                    <div>
                      {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                      {/* <br /> */}

                      <div className="flex jcsb flex-wrap mb-4">
                        <Search
                          placeholder="Pesquisar pelo enunciado da questão"
                          ValueChange={(value) => {
                            clearTimeout(this.pesquisarTimeout);
                            this.pesquisarTimeout = setTimeout(() => {
                              let page = { ...this.state.pagination };
                              page.current_page = 1;
                              this.setState({
                                pagination: page,
                                search: value,
                                loading_search: true,
                              });
                              this.get_questoes(1, value);
                            }, 500);
                          }}
                        />

                        {(this.state.demanda_professor
                          .numero_questoes_dissertativas == null ||
                          this.state.demanda_professor.numero_questoes ==
                            null ||
                          this.state.demanda_professor.questoes +
                            this.state.demanda_professor
                              .questoes_dissertativas <
                            this.state.demanda_professor.numero_questoes +
                              this.state.demanda_professor
                                .numero_questoes_dissertativas) && (
                          <Link
                            to={{
                              pathname:
                                "/atribuicao/questoes/register/" +
                                this.props.match.params.demanda_id,
                              // state: {
                              //     course_id: id
                              // }
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="success"
                              className="flex align-center jcc"
                            >
                              <FaListAlt style={{ marginRight: 10 }} />{" "}
                              <p style={{ margin: 0 }}>Adicionar questão</p>
                            </Button>
                          </Link>
                        )}
                      </div>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table table-striped"
                          >
                            <thead>
                              <tr>
                                <th>Enunciado</th>
                                <th>Alternativa Correta</th>
                                <th>Tipo de Questão</th>
                                <th>Status da Questão</th>

                                <th data-priority="3">Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.loading_search == false &&
                                this.state.questoes.map((item, id) => (
                                  <tr key={id}>
                                    <td
                                      style={{
                                        textAlign: "justify",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item.text_questao}
                                    </td>
                                    {item.type_questao == "simples" && (
                                      <td>{item.correta})</td>
                                    )}
                                    {(item.type_questao == "conjunto" ||
                                      item.type_questao == "dissertativa") && (
                                      <td>-</td>
                                    )}
                                    <td>
                                      {item.type_questao == "conjunto"
                                        ? "Conjunto de questões - " +
                                          item.questoes.length +
                                          (item.questoes.length == 1
                                            ? " questão"
                                            : " questões")
                                        : item.type_questao == "simples"
                                        ? "Questão única"
                                        : "Questão dissertativa"}
                                    </td>
                                    <td>
                                      {item.statusAprovacao === "aprovado" ||
                                      item.statusAprovacao === "concluido"
                                        ? "Aprovada"
                                        : item.statusAprovacao === "pendente"
                                        ? "Pendente"
                                        : "Recusada"}
                                    </td>

                                    {/* <td>Questão {id + 1}</td> */}
                                    <td>
                                      <span
                                        onClick={() => {
                                          this.setState({
                                            questaoSelect: { ...item },
                                            showModal: true,
                                          });
                                        }}
                                        className="inline-flex align-center jcc bg-warning icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                        }}
                                        title="Gerenciar questão"
                                      >
                                        <BsFillEyeFill
                                          size={17}
                                          color="white"
                                        />
                                      </span>

                                      {item.andamento == false && (
                                        <Link
                                          to={`/atribuicao/questoes/edit/${item.id}`}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <span
                                            className="inline-flex align-center jcc bg-info icon"
                                            style={{
                                              width: "2em",
                                              height: "2em",
                                              borderRadius: "7px",
                                              marginRight: "2px",
                                              marginLeft: "2px",
                                            }}
                                            data-toggle="tooltip"
                                            title="Editar questão"
                                          >
                                            <MdModeEdit
                                              color="white"
                                              size={17}
                                            />
                                          </span>
                                        </Link>
                                      )}
                                      {/* <span
                                                                        onClick={(e) => {
                                                                            this.questao_id = item.id;
                                                                            this.setState({ show_warning: true });
                                                                        }}
                                                                        className="inline-flex align-center jcc bg-danger icon"
                                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                        title="Deletar questão"
                                                                    >
                                                                        <MdDelete size={17} color="white" />
                                                                    </span> */}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {this.state.loading_search == true && (
                        <div className="row" style={{ margin: 0 }}>
                          {" "}
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <CircularProgress />
                          </div>
                        </div>
                      )}
                      {this.state.loading_search == false &&
                        this.state.questoes.length == 0 && (
                          <div className="row w-100">
                            <div className="col-12">
                              <p style={{ textAlign: "center" }}>
                                Nenhuma questão cadastrada nesta demanda
                              </p>
                            </div>
                          </div>
                        )}

                      <Pagination
                        className={
                          this.state.pagination.last_page == 1 ? "hide" : ""
                        }
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={this.state.pagination.last_page}
                        value={this.state.pagination.current_page}
                        onChange={(_, value) => {
                          console.log(_, value);
                          let page = { ...this.state.pagination };
                          page.current_page = value;
                          this.setState({ pagination: page });
                          this.get_questoes(value, this.state.search);
                        }}
                      />

                      {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                    </div>
                  )}

                  {this.state.loading_screen == true && (
                    <div className="row">
                      {" "}
                      <div
                        className="col-12 d-flex justify-content-center align-items-center"
                        style={{ marginTop: "2rem" }}
                      >
                        {/* <div className="spinner-border text-primary" role="status"  >
        </div> */}
                        <CircularProgress />
                      </div>
                    </div>
                  )}

                  <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                      this.setState({
                        show_warning: false,
                        loading_search: true,
                      });
                      this.delete_questao(this.questao_id);
                      // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                      this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText="Sim, desejo apagar"
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                  >
                    Ao deletar esta questão, ela não estará mais disponível no
                    sistema
                  </SweetAlert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(Questoes);

// Library Imports
import { Modal, Button } from "react-bootstrap";

const SuccessModal = ({ show, handleClose }) => {

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Sucesso!</Modal.Title>
      </Modal.Header>
      <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
